<!--  -->
<template>
  <div class="wrapper">
    <div class="searchbox">
      <el-form :inline="true" class="demo-form-inline">
        <el-form-item>
          <el-button type="primary" v-if="tableData.length>0" @click="getList" icon="el-icon-refresh">刷新</el-button>
          <el-button type="primary" v-if="tableData.length>0" @click="exportXcle" icon="el-icon-download">导出</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tablebox">
      <el-table
        :data="tableData"
        style="width: 100%"
        id="exportTable"
        show-summary
        :header-cell-style="{fontWeight: '700', fontSize: '14px'}">
        <el-table-column type="index" label="序号" align="center" width="100"></el-table-column>
        <el-table-column prop="communityName" label="社区" align="center"></el-table-column>
        <el-table-column prop="volunteerCount" label="社员人数" align="center"></el-table-column>
        <el-table-column prop="cjVolunteerCount" label="特殊困难群体人数" align="center"></el-table-column>
        <el-table-column prop="cpcVolunteerCount" label="党员人数" align="center"></el-table-column>
        <el-table-column prop="incomePoint" label="消费积分收入" align="center"></el-table-column>
        <el-table-column prop="generalUsablevolPoint" label="荣誉积分收入" align="center"></el-table-column>
        <el-table-column prop="generalPoint" label="消费积分" align="center"></el-table-column>
        <el-table-column prop="generalUsablePoint" label="已消费积分" align="center"></el-table-column>
        <el-table-column prop="honerPoint" label="荣誉积分" align="center"></el-table-column>
        <el-table-column prop="totalPoint" label="总积分" align="center"></el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { communityVolPointsList, exportExcel } from '@/api/reportStatistics'
export default {
  data () {
    return {
      tableData: [],
    };
  },

  components: {},

  computed: {},

  mounted(){
    this.getList();
  },

  methods: {
    getList() {
      communityVolPointsList().then(res => {
        if(res.result == 200) {
          this.tableData = res.detail;
        }
      })
    },
    exportXcle() {
      exportExcel({
        type: 1
      }).then(res => {
        const content = res;
        const blob = new Blob([content]) //构造一个blob对象来处理数据
        const fileName =  '沿江街道各社区社员情况统计.xlsx';
        const link = document.createElement('a') //创建a标签
        link.download = fileName //a标签添加属性
        link.style.display = 'none'
        link.href = URL.createObjectURL(blob)
        document.body.appendChild(link)
        link.click() //执行下载
        URL.revokeObjectURL(link.href) //释放url
        document.body.removeChild(link) //释放标签
      })
    },
  }
}

</script>
<style lang='scss' scoped>
</style>